<template>                         
    <div class="card">
        <div class="card-header">
            {{titulo}}
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    Name: 'Card',
    props: ['titulo', 'texto'],  
    data () {
        return {
            list:{}, 
        }
    },
}
</script>